<template>
  <section class="container mb-14 sm:mb-20">
    <div class="container__inner sub-page pt-6 md:pt-12">
      <div class="mb-14 sm:mb-20">
        <h1 class="text-royalblue-900 mb-10">Testimonials</h1>
        <div class="flex flex-wrap -m-4">
          <div class="w-full p-4">
            <h2 class="h5 text-royalblue-400 mb-4 leading-relaxed">
              Latest testimonials
            </h2>
            <p>
              Even during COVID restrictions the therapist maintained correct
              proceedures. The fact that I was able to be seen in my own home
              made me more comfortable. The therapist was able to see how I
              managed doing every day tasks. I would recommend Balance Neuro
              Physiotherapy.
            </p>
            <p>
              I would be happy to recommend Balance Neuro physiotherapy to
              anyone requiring similar help to me. Regular physiotherpay in your
              own home is a great advantage to cope with everyday living.
              Patients are encouraged to increase their capabilities gradually
              on each visit.
            </p>
            <p>
              Balance Neuro Physio is very professional in all matters- Happy to
              recommend to all.
            </p>
            <p>
              I can't praise Balance Neuro Physiotherapy enough. If it wasn't
              for their knowledge and practical skills I would not be so far
              advanced in my recovery after my stroke. I cannot thank the team
              enough. Getting me back to normal life over the last year, I have
              become independent through the hard work of the team. Not only the
              physiotherapy, I commend that they have kept my mental health
              strong. thank you Di, Gemma and Ali for getting me walking and my
              arm working again. Can't give enough praise to this great team.
            </p>
            <p>
              Diane was very kind and understanding. She gave me confidence
              through a difficult time after a fall at home.
            </p>
            <p>
              I contacted Diane and the team as they were recommended by a
              colleague. I cannot praise them enough for all they have done for
              my mum. They are patient, kind but also so encouraging. Mum has
              come on in leaps and bounds. Thank you.
            </p>
            <p>
              My symptoms started suddenly and I felt very unwell. Initially it
              was suspected to be labyrinthitis and I had to take time off work
              as I just couldn't function. Adrian was recommended to me, so we
              arranged a consultation during which he quickly diagnosed my
              condition as BPPV. He took the time to explain in detail what BPPV
              was and how to deal with it, plus how the treatment would work.
              The procedure was conducted quickly and I experienced an immediate
              improvement. His advice and support were much appreciated, as was
              the follow up to check on progress. I would fully recommend Adrian
              without hesitation.
            </p>
            <h2 class="h5 text-royalblue-400 mb-4 leading-relaxed">
              December 2019
            </h2>
            <p>
              We were delighted to find 'Heather Ross' who agreed to visit me at
              home and assessed me. Soon after Heather started it became clear
              to us that she had the knowledge and professional skills to help
              me recover. Since then Heather has visited me twice a week and
              helped me to make significant progress. Heather's experience and
              wealth of knowledge is boundless. She has organized other support
              services funded by the NHS which have contributed to my recovery.
            </p>
            <p>
              Heather's positive, patient attitude with constant encouragement
              has been most beneficial and has completely transformed my outlook
              on my future life.
            </p>
            <h2 class="h5 text-royalblue-400 mb-4 leading-relaxed">
              November 2019
            </h2>
            <p>I am very happy with the service.</p>
            <h2 class="h5 text-royalblue-400 mb-4 leading-relaxed">
              October 2019
            </h2>
            <p>
              I found my visit from Diane to discuss my treatment etc very
              calming and I did feel that she calmed me down and it made a very
              good start for me.
            </p>
            <h2 class="h5 text-royalblue-400 mb-4 leading-relaxed">
              September 2019
            </h2>
            <p>
              To feel that I was in control of my situation was very important
              to me, and to have a giggle when things went a bit wrong. Thank
              you.
            </p>
            <h2 class="h5 text-royalblue-400 mb-4 leading-relaxed">
              August 2019
            </h2>
            <p>
              Not only has my husband improved his mobility, he enjoys the
              sessions immensly and looks forward to the next one with pleasure.
              They have improved his quality of life. Diane and Ali make
              exercise fun and we have lots of laughs.
            </p>
            <h2 class="h5 text-royalblue-400 mb-4 leading-relaxed">
              November 2018
            </h2>
            <p>
              Gemma is a really great physiotherapist. She will work hard with
              you to get the best out of you and make big improvements. And you
              will feel mentally better as well as physically.
            </p>
            <h2 class="h5 text-royalblue-400 mb-4 leading-relaxed">
              October 2018
            </h2>
            <p>
              Gemma is a highly qualified and experienced neuro-physiotherapist
              with an astonishing range of skills. She has cared for my wife
              Margaret since her very severe stroke nearly three years ago with
              great tenderness and sensitivity to her condition. Gemma is
              particularly skilled in managing the relationship between
              cognitive and neurological capacities and their physiological
              manifestations.
            </p>
            <p>
              This enables Gemma to develop and implement treatments and
              exercises which are focused on the maintenance and recovery of
              specific capacities lost through brain injury. Gemma has enabled
              significant progress in Margaret's capabilkity in walking with a
              frame and in overcoming stiffness in her left hand.
            </p>
            <p>
              Gemma has a sunny and persistently cheerful disposition with those
              for whom she cares and their family members. She makes very
              positive and helpful therapeutic relationships. Gemma is an
              excellent communicator and takes care to tailor the explanation of
              her exercises and therapies to different people's capacities.
            </p>
            <h2 class="h5 text-royalblue-400 mb-4 leading-relaxed">
              September 2018
            </h2>
            <p>
              Kind, caring, thoughtful and always keen to find a solution to a
              problem. Gemma has cared for me for a few months now and I have
              made great progress. I always feel better after our sessions and
              highly recommend her skills.
            </p>
            <h2 class="h5 text-royalblue-400 mb-4 leading-relaxed">
              August 2018
            </h2>
            <p>
              Considerate, reliable treatment at every visit which achieved its
              objective. Di has 'healing hands' and seems to get right to the
              problem.
            </p>
            <h2 class="h5 text-royalblue-400 mb-4 leading-relaxed">
              August 2018
            </h2>
            <p>
              My neurophysiotherapy treatment has been life changing.I
              understand so much more about what I can be doing to correct my
              positioning, what is going on with my muscles and connective
              tissues and my pelvis. Also why things are not right about my
              posture. It's an ongoing learning experience and the knowledge of
              Diane is awesome. We are working on a lot of connected areas to
              make a long term difference.
            </p>
            <h2 class="h5 text-royalblue-400 mb-4 leading-relaxed">
              March 2018
            </h2>
            <p>
              Diane of Balance Neuro, has been treating me since June 2017 for
              my acquired brain-injury related problems. I could not be happier
              with the treatment I have received! Diane has from the start been
              proactive at ensuring I have the best care and treatment, and has
              also recommended various items of equipment which may assist me.
            </p>
            <p>
              Each has, and have to greater or lesser extent been life-changing.
              I am not cured because of Diane's input, but she has in all ways
              possible managed to bring about positive changes in my life whilst
              always ensuring that I feel cared for, nurtured and treated with
              the highest standard of neuro-physiotherapy.
            </p>
            <h2 class="h5 text-royalblue-400 mb-4 leading-relaxed">
              July 2016
            </h2>
            <p>
              After having a serious stroke and needing the input of a physio
              for the first time in my life, I have been extremely impressed
              with the approach of Balance Neuro. The combination of a wealth of
              professional experience and practical application is very
              reassuring. I always feel very inspired following a physio
              session.
            </p>
            <h2 class="h5 text-royalblue-400 mb-4 leading-relaxed">
              June 2016
            </h2>
            <p>
              I have been impressed with the service provided and feel it has
              been a positive experience.
            </p>
            <h2 class="h5 text-royalblue-400 mb-4 leading-relaxed">May 2016</h2>
            <p>
              You have treated me with kindness and respect at all times. I have
              been very pleased with my treatment and with your respect, and the
              way you explained everything to me from the start.
            </p>
            <h2 class="h5 text-royalblue-400 mb-4 leading-relaxed">
              March 2016
            </h2>
            <p>
              I would like to thank Diane for being so accomodating in going out
              of her way to visit and help me, even though I live out of her
              way. She made numerous phone calls to make arrangements for me,
              and has kept my family regularly updated via email. She has been
              so helpful and kind, and has given me hope that I may get more
              mobile in the future.
            </p>
            <h2 class="h5 text-royalblue-400 mb-4 leading-relaxed">J.D.</h2>
            <p>
              Diane has been treating me following a severe stroke. Her approach
              is calm, positive and encouraging. Being treated at home allows
              her to show great practicality by using everyday objects and
              equipment that are readily available. Her exercises have been well
              explained and demonstrated to enable practice between sessions to
              be easily undertaken. Diane supplies printed instructions as a
              useful reminder. She is always prepared to answer queries by phone
              or email.
            </p>
            <h2 class="h5 text-royalblue-400 mb-4 leading-relaxed">P.</h2>
            <p>
              I am a 47 year young female with primary progressive MS of ten
              years. I look forward each week to Helen coming to my house. Helen
              is punctual and reliable. Diane and Helen are very caring,
              encouraging and motivating people. Balance Physiotherapy has much
              knowledge to share. This information has been given to me in a
              professional and loving way, with exercises I can do for myself in
              between weeks. Diane has enlarged the sheets of exercises so I can
              see them and I have been lent equipment to suit my needs to use at
              home.
            </p>
            <p>
              My balance is better and standing feels stronger. I have learnt to
              engage and strengthen my core muscles and also to relax more which
              has had huge advantages. Thank you Balance Physiotherapy for the
              vital, affordable input you are giving to me.
            </p>
            <h2 class="h5 text-royalblue-400 mb-4 leading-relaxed">J.P.</h2>
            <p>
              Recently I started to experience a very unusual pain in my
              shoulder which extended down my arm. Having never been to a
              physiotherapist before, I really didn't know what to expect, but
              from the initial consultation through to my follow up appointments
              I have been nothing but impressed with Balance Physiotherapy.
            </p>
            <p>
              Firstly I love the fact that the sessions are carried out in the
              comfort and privacy of your own home. Diane has a very calming,
              professional and friendly manner and explains the area she is
              working on in laymen's terms, infact each session has been a real
              education! Not only have the physio sessions helped me with my
              recovery, but Diane has worked with me to help my posture,
              providing me with relevant exercises to prevent the pain from
              returning. With a ballet exam coming up very soon, Diane has
              helped me enormously to regain the strength in my arm to complete
              it with confidence.
            </p>
            <h2 class="h5 text-royalblue-400 mb-4 leading-relaxed">N</h2>
            <p>
              I am 45 years old and my main hobby is playing guitar. My job as a
              computer engineer requires me to type so my hands are very
              important to me. About a year ago I had a very painful accident
              lifting a bag of shopping causing a rupture of a sagittal tendon
              band in my finger which required surgery to correct.
            </p>
            <p>
              9 months passed and my hand was still not fully recovered. I had
              lost flexibility and strength in my hand so much so I could not
              cut bread properly or hold garden tools safely, my guitar playing
              had also deteriorated. My hand was also painful in the morning
              when I woke up. Knowing one of the areas Balance Physiotherapy is
              interested in is hands, I asked for an assessment to see if
              anything could be done to help. During the assessment flexibility,
              strength, micro and macro movement were checked, these were the
              areas I felt had deteriorated since surgery.
            </p>
            <p>
              I found Diane and her colleague Helen very easy to work with and
              it raised my hopes when they told me something could be done to
              get my hand back to normal. Once the assessment was complete I was
              given a set of exercises to do and shown how to make sure I was
              doing them safely and correctly as well as the benefits they would
              bring. They even said guitar playing could form part of the
              therapy! I was also given a target to aim for and told how soon I
              could expect to see an improvement. After the assessment, Diane
              quickly emailed a document to me describing the exercises with
              annotated pictures. In a short period I saw improvements with
              flexibility and strength. Now 4 weeks later I have had a follow up
              review with some more exercises added. My guitar playing is
              improving which is very important to me.
            </p>
            <p>
              The benefits of going to Balance Physiotherapy have been the ease
              of making appointments to suit my working hours, home visit,
              certified physiotherapist advice and above all, treatment that has
              worked far better than I expected. I have recommended Balance
              Physiotherapy to my guitar tutor for other students who have
              problems. I would also not hesitate to recommend them to anyone
              else in need of physio treatment.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Testimonials",
};
</script>
